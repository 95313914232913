import ApiService from "../util/api.service";

import utilService from "../util/utils.service";
import { SERVICE_CONFIG_URLS } from "./config";

const ActivityService = {
  createEvent,
  updateEvent,
  getActivity,
  deleteActivity,
  validateActivity,
  getTopEvents,
  cancelActivity
};

async function validateActivity(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.ACTIVITY.VALIDATE_CONTENT,
    payload
  );
  return response;
}

async function updateEvent(payload, id) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.ACTIVITY.UPDATE_ACTIVITY,
    { id }
  );
  const response = await ApiService.put(url, payload);
  return response;
}

async function createEvent(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.ACTIVITY.CREATE_ACTIVITY,
    payload
  );
  return response;
}

async function getActivity(query) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.ACTIVITY.GET_ACTIVITY_DETAILS,
    query
  );
  const response = await ApiService.get(url);
  return response;
}

async function deleteActivity(query, type) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.ACTIVITY.DELETE_ACTIVITY,
    query
  );
  const response = await ApiService.delete(url, type);
  return response;
}

async function getTopEvents(query) {
  const response = await ApiService.get(
    SERVICE_CONFIG_URLS.ACTIVITY.GET_TOP_EVENTS,
    query
  );
  return response;
}

async function cancelActivity(query, type) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.ACTIVITY.CANCEL_ACTIVITY,
    query
  );
  const response = await ApiService.delete(url, type);
  return response;
}
export default ActivityService;
