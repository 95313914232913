import { EVENT_IS_BOOSTED_KEYS } from "../../constants/constant";
import { ACTIVITY_TYPE, RECURRENCE_TYPE } from "../../constants/constantTypes";
import dateTimeService, {
  DateToSpecificTZV2,
  formatDateToSpecificTZv2,
} from "../../util/date-time.service";
import utilService from "../../util/utils.service";

export const createUploadFormData = (files, types) => {
  let formData = [];
  let retainedIds = [];

  let form = new FormData();

  files.forEach((file) => {
    if (file?.originFileObj) {
      form.append("files", file.originFileObj);
    } else {
      // TODO verify either use ID or UID
      retainedIds.push(file.uid);
    }
  });
  return form;
};

export const createUploadFormAccessData = (files, types) => {
  let formData = [];
  let retainedIds = [];

  let form = new FormData();
  form.append("CategoryType", types);
  files.forEach((file) => {
    if (file?.originFileObj) {
      form.append("file", file.originFileObj);
    } else {
      // TODO verify either use ID or UID
      retainedIds.push(file.uid);
    }
  });
  return form;
};

export const findBoostedOptions = (top5Excluded, isBoosted, isFeatured) => {
  if (top5Excluded) return EVENT_IS_BOOSTED_KEYS.EXCLUDE;
  if (isBoosted) return EVENT_IS_BOOSTED_KEYS.INCLUDE;
  if (isFeatured) return EVENT_IS_BOOSTED_KEYS.ALWAYS;
  return EVENT_IS_BOOSTED_KEYS.EXCLUDE;
};

export const mapInitialFormState = (state) => {
  if (state) {
    return {
      isRecurring: state.isRecurring,
      requiresSignUp: state.requiresSignUp,
      boostedOptions: findBoostedOptions(
        state.top5Excluded,
        state.isBoosted,
        state.isFeatured
      ),
      originalMediaFiles: state.images || [],
      type: state.type || ACTIVITY_TYPE.EVENT,
      mediaIds: (state.images || []).map((image) => image.id),
      host: utilService.replaceNullWithPlaceholder(state.host, ""),
      title: utilService.replaceNullWithPlaceholder(state.title, ""),
      website: utilService.replaceNullWithPlaceholder(state.website, ""),
      description: utilService.replaceNullWithPlaceholder(
        state.description,
        ""
      ),
    };
  }
  return null;
};

const mapDateObject = (state) => {
  if (!state.isRecurring) {
    return dateTimeService.DateToSpecificTZ(
      state.dateTimeDetails?.[0]?.startDate
    );
  }
  if (state.recurrenceType === RECURRENCE_TYPE.SIMPLE) {
    return [
      dateTimeService.DateToSpecificTZ(state?.recurrenceStartDate),
      dateTimeService.DateToSpecificTZ(state?.recurrenceEndDate),
    ];
  }
  return null;
};

const getStartAndEndTime = (state) => {
  if (state.isRecurring) {
    if (state.recurrenceType === RECURRENCE_TYPE.COMPLEX) {
      return {
        dateTimeDetails: state.dateTimeDetails.map((el) => ({
          ...el,
          endDate: dateTimeService.DateToSpecificTZFormated(el?.endDate),
          startDate: dateTimeService.DateToSpecificTZFormated(el?.startDate),
        })),
      };
    }
    return {
      endTime: dateTimeService.DateToSpecificTZ(state?.recurrenceEndDate),
      startTime: dateTimeService.DateToSpecificTZ(state?.recurrenceStartDate),
    };
  } else {
    return {
      endTime: dateTimeService.DateToSpecificTZ(
        state.dateTimeDetails?.[0]?.endDate
      ),
      startTime: dateTimeService.DateToSpecificTZ(
        state.dateTimeDetails?.[0]?.startDate
      ),
      // endTime: DateToSpecificTZV2(state.dateTimeDetails?.[0]?.endDate),
      // startTime: DateToSpecificTZV2(state.dateTimeDetails?.[0]?.startDate)
    };
  }
};

export const mapFinalFormState = (state) => {
  if (state) {
    return {
      dates: mapDateObject(state),
      ...getStartAndEndTime(state),
      costType: state.costType,
      address: state.address || null,
      tagIds: state.tags.map((el) => el.id),
      recurrenceType: state.recurrenceType,
      discountedTo: state?.discountedTo || 0,
      discountedFrom: state?.discountedFrom || 0,
      recurrences: state.dateTimeDetails?.[0]?.every,
      ageGroups: state.ageGroups.map((el) => el.ageGroup),
      accomodationIds: state.accomodations.map((el) => el.id),
      transportationIds: state.transportations.map((el) => el.id),
    };
  }
  return null;
};
