import ApiService from "../util/api.service";
import utilService from "../util/utils.service";
import { SERVICE_CONFIG_URLS } from "./config";

const PortalService = {
  getActivities,
  updateActivityStatus,
  reportedActivities,
  dismissReports,
  getAdministrators,
  freebieCategory,
  deleteFreebieCategory,
  freebieCategoryOrder,
  editFreebieCategory,
  reportedFreebies,
  reportedChat,
  deleteChatTopic,
  getSuggestedTopic,
  approveTopic,
  addTopic,
  editTopic,
  getUsers,
  getNotifications,
  getCommunityPartners,
  updateCommunityPartner,
  modifyTopEvents,
  getTopEventList,
  getTopEvents,
  addConnectCategory,
  updateConnectCategory,
  deleteConnectCategory,
  updateConnectCategoryOrder,
  addActivityCategory,
  updateActivityCategory,
  deleteActivityCategory,
  updateActivityCategoryOrder,
  getChildrenActivities,
  deleteAccessCategory,
  addAccessCategory,
  updateAccessCategory,
  changeAccessCategoryOrder,
  getAnnouncementListing,
  getArticleListing,
  getTopFiveByUser,
  getFreebiesListing,
  updateEducationSupportCategory,
  addEducationSupportCategory,
  changeEducationSupportCategoryOrder,
  deleteEducationSupportCategory,
  addTopFiveAnnouncement,
  getTopFiveAnnouncementList,
  getTopFiveAnnouncementDetails,
  updateTopFiveAnnouncement,
  getAnnouncementValidation,
  deleteAnnouncement,
};

async function getActivities(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.ACTIVITY_LIST,
    payload
  );
  return response;
}

async function freebieCategory() {
  const response = await ApiService.get(
    SERVICE_CONFIG_URLS.PORTAL.FREEBIE_CATEGORY
  );
  return response;
}

async function reportedFreebies(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.REPORTED_FREEBIES,
    payload
  );
  return response;
}

// get freebies listing
async function getFreebiesListing(payload) {
  const response = await ApiService.get(
    SERVICE_CONFIG_URLS.FREEBIE.GET_LISTING,
    payload
  );
  return response;
}

async function editFreebieCategory({ id, ...payload }) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DELETE_FREEBIE_CATEGORY,
    { id }
  );
  const response = await ApiService.put(url, payload);
  return response;
}

async function freebieCategoryOrder(payload) {
  const response = await ApiService.put(
    SERVICE_CONFIG_URLS.PORTAL.FREEBIE_CATEGORY_ORDER,
    payload
  );
  return response;
}

async function deleteFreebieCategory(id) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DELETE_FREEBIE_CATEGORY,
    { id }
  );
  const response = await ApiService.delete(url);
  return response;
}

async function updateActivityStatus(activityId, payload) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.UPDATE_ACTIVITY_STATUS,
    activityId
  );
  const response = await ApiService.put(url, payload);
  return response;
}

async function reportedActivities(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.REPORTED_ACTIVITIES,
    payload
  );
  return response;
}

async function dismissReports(query, payload) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DISMISS_REPORTS,
    query
  );
  const response = await ApiService.put(url, payload);
  return response;
}

async function reportedChat(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.REPORTED_CHATS,
    payload
  );
  return response;
}
async function getAdministrators(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.ADMINISTRATORS,
    payload
  );
  return response;
}

async function deleteChatTopic(id) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DELETE_CHAT_TOPIC,
    { id }
  );
  const response = await ApiService.delete(url);
  return response;
}

async function getSuggestedTopic(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.SUGGESTED_TOPICS,
    payload
  );
  return response;
}

async function approveTopic(id, payload) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.APPROVE_TOPIC,
    { id }
  );
  const response = await ApiService.put(url, payload);
  return response;
}

async function addTopic(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.ADD_TOPIC,
    payload
  );
  return response;
}

async function editTopic(id, payload) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.EDIT_TOPIC,
    { id }
  );
  const response = await ApiService.put(url, payload);
  return response;
}
async function getUsers(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.USERS,
    payload
  );
  return response;
}

async function getNotifications(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.NOTIFICATION_LIST,
    payload
  );
  return response;
}

async function getCommunityPartners(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.COMMUNITY_PARTNERS,
    payload
  );
  return response;
}

async function updateCommunityPartner(id, payload) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.UPDATE_COMMUNITY_PARTNER,
    id
  );
  const response = await ApiService.put(url, payload);
  return response;
}

async function modifyTopEvents(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.MODIFY_TOP_ACTIVITIES,
    payload
  );
  return response;
}

// add announcement call
async function addTopFiveAnnouncement(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.ADD_TOP_FIVE_ANNOUNCEMENT,
    payload
  );
  return response;
}

// update announcement call
async function updateTopFiveAnnouncement({ id, ...payload }) {
  const response = await ApiService.put(
    `${SERVICE_CONFIG_URLS.PORTAL.UPDATE_TOP_FIVE_ANNOUNCEMENT}/${id}`,
    payload?.payload
  );
  return response;
}

// announcement get list
async function getTopFiveAnnouncementList(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.TOP_FIVE_ANNOUNCEMENT_LIST,
    payload
  );
  return response;
}

//get announcement by date
async function getTopFiveAnnouncementDetails(query) {
  const response = await ApiService.get(
    `${SERVICE_CONFIG_URLS.PORTAL.GET_TOP_FIVE_ANNOUNCEMENT}/${query?.id}`
  );
  return response;
}

//get validation of date
async function getAnnouncementValidation(payload) {
  const response = await ApiService.post(
    `${SERVICE_CONFIG_URLS.PORTAL.GET_VALIDATE_ANNOUNCEMENT}?date=${payload?.date}`
  );
  return response;
}
async function getTopEventList(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.TOP_EVENT_LIST,
    payload
  );
  return response;
}

async function getTopEvents(query) {
  const response = await ApiService.get(
    SERVICE_CONFIG_URLS.PORTAL.GET_TOP_EVENTS,
    query
  );
  return response;
}

async function addConnectCategory(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.ADD_CONNECT_CATEGORY,
    payload
  );
  return response;
}
async function updateConnectCategory(id, payload) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.UPDATE_CONNECT_CATEGORY,
    { id }
  );
  const response = await ApiService.put(url, payload);
  return response;
}

async function deleteConnectCategory(id) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DELETE_CONNECT_CATEGORY,
    { id }
  );
  const response = await ApiService.delete(url);
  return response;
}
async function updateConnectCategoryOrder(payload) {
  const response = await ApiService.put(
    SERVICE_CONFIG_URLS.PORTAL.CHANGE_CATEGORY_ORDER,
    payload
  );
  return response;
}

async function addActivityCategory(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.ADD_ACTIVITY_CATEGORY,
    payload
  );
  return response;
}

async function updateActivityCategory(id, payload) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.UPDATE_ACTIVITY_CATEGORY,
    { id }
  );
  const response = await ApiService.put(url, payload);
  return response;
}
async function deleteAccessCategory(id) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DELETE_ACCESS_CATEGORY,
    { id }
  );
  const response = await ApiService.delete(url);
  return response;
}
async function changeAccessCategoryOrder(payload) {
  const response = await ApiService.put(
    SERVICE_CONFIG_URLS.PORTAL.ACCESS_CATEGORY_ORDER,
    payload
  );
  return response;
}

async function addAccessCategory(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.ADD_ACCESS_CATEGORY,
    payload
  );
  return response;
}

async function updateAccessCategory({ id, ...payload }) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.UPDATE_ACCESS_CATEGORY,
    { id }
  );
  const response = await ApiService.put(url, payload);
  return response;
}
async function deleteEducationSupportCategory(id) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DELETE_EDUCATION_SUPPORT_CATEGORY,
    { id }
  );
  const response = await ApiService.delete(url);
  return response;
}
async function changeEducationSupportCategoryOrder(payload) {
  const response = await ApiService.put(
    SERVICE_CONFIG_URLS.PORTAL.EDUCATION_SUPPORT_CATEGORY_ORDER,
    payload
  );
  return response;
}

async function addEducationSupportCategory(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.ADD_EDUCATION_SUPPORT_CATEGORY,
    payload
  );
  return response;
}

async function updateEducationSupportCategory({ id, ...payload }) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.UPDATE_EDUCATION_SUPPORT_CATEGORY,
    { id }
  );
  const response = await ApiService.put(url, payload);
  return response;
}

async function deleteActivityCategory(id) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DELETE_ACTIVITY_CATEGORY,
    { id }
  );
  const response = await ApiService.delete(url);
  return response;
}
async function updateActivityCategoryOrder(payload) {
  const response = await ApiService.put(
    SERVICE_CONFIG_URLS.PORTAL.CHANGE_ACTIVITY_CATEGORY_ORDER,
    payload
  );
  return response;
}
async function getChildrenActivities(payload) {
  const response = await ApiService.post(
    SERVICE_CONFIG_URLS.PORTAL.CHILDREN_ACTIVITIES,
    payload
  );
  return response;
}
async function getAnnouncementListing(payload) {
  const response = await ApiService.get(
    SERVICE_CONFIG_URLS.PORTAL.ANNOUNCEMENT_LISTING,
    payload
  );
  return response;
}
async function getArticleListing(payload) {
  const response = await ApiService.get(
    SERVICE_CONFIG_URLS.PORTAL.ARTICLES_LISTING,
    payload
  );
  return response;
}

async function getTopFiveByUser(accountId, params) {
  console.log(params);
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.GET_TOP_FIVE_ACTIVITIES_BY_USER,
    { accountId }
  );
  const response = await ApiService.get(url, params);
  return response;
}

async function deleteAnnouncement(id) {
  const url = utilService.createDynamicUrl(
    SERVICE_CONFIG_URLS.PORTAL.DELETE_ANNOUNCEMENT,
    { id }
  );
  const response = await ApiService.delete(url);
  return response;
}

export default PortalService;
