import { useState } from "react";
import { PAGINATION_CONSTANT, PAYLOAD_KEY } from "./../constants/constant";
const useGridRequest = (props) => {
  const INITIAL_VALUES = {
    pagination: props?.pagination ?? {
      offset: 0,
      count: PAGINATION_CONSTANT?.PAGE_SIZE,
    },
    searchBy: props?.searchBy ?? "",
    sort: props?.sort ?? { name: "", orderBy: "" },
    filters: props?.filters ?? [],
    fromDate: props?.fromDate ?? null,
    toDate: props?.toDate ?? null,
  };
  const [gridRequest, setGridRequest] = useState(INITIAL_VALUES);

  const onSearch = (searchValue) => {
    const request = { ...gridRequest, searchBy: searchValue };
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const onDateSearch = (fromDate, toDate) => {
    const request = { ...gridRequest, fromDate: fromDate, toDate: toDate };
    setGridRequest(request);
  };

  const onPagination = (offset, count) => {
    const request = {
      ...gridRequest,
      pagination: { offset: offset, count: count },
    };
    setGridRequest({
      ...request,
    });
  };

  const onSort = (columName, orderBy) => {
    const request = {
      ...gridRequest,
      sort: { name: columName, orderBy: orderBy },
    };
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const clearSort = () => {
    const request = { ...gridRequest, sort: { name: "", orderBy: "" } };
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const onFilter = (key, value, optionalPayload = {}) => {
    let request = { ...gridRequest, ...optionalPayload };
    request[key] = value;
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const onMultiFilters = (obj, optionalPayload = {}) => {
    let request = { ...gridRequest, ...optionalPayload };
    for (const key in obj) {
      request[key] = obj[key];
    }

    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const clearFilters = () => {
    const request = { ...gridRequest, filters: [] };
    request.pagination.offset = 0;
    setGridRequest(request);
  };

  const resetPayload = () => {
    const request = { ...gridRequest, ...INITIAL_VALUES };
    setGridRequest(request);
  };

  const changePageCount = (increment = 5) => {
    const request = {
      ...gridRequest,
      pagination: {
        ...gridRequest?.pagination,
        count: gridRequest?.pagination?.count + increment,
      },
    };
    setGridRequest(request);
  };

  const getNormalizeRequest = (props) => {
    const { pagination, searchBy, fromDate, toDate, filters, sort, ...rest } =
      gridRequest;

    const payload = {
      [PAYLOAD_KEY.OFFSET]: pagination.offset,
      [PAYLOAD_KEY.COUNT]: pagination.count,
      searchBy: searchBy,
      fromDate: fromDate,
      toDate: toDate,
      [PAYLOAD_KEY.SORT_NAME]: sort.name,
      [PAYLOAD_KEY.SORT_ORDERBY]: sort.orderBy,
      ...props,
      ...rest,
    };
    return payload;
  };

  return {
    gridRequest,
    onSearch,
    onFilter,
    onPagination,
    onSort,
    clearFilters,
    clearSort,
    resetPayload,
    changePageCount,
    onDateSearch,
    onMultiFilters,
    getNormalizeRequest,
  };
};

export default useGridRequest;
