import moment from "moment";
import React, { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Button,
  Card,
  DatePicker,
  TimePicker,
  Skeleton,
  Checkbox,
} from "antd";

import { Patterns } from "../../../../constants/ActionTypes";
import InAppNotificationURI from "../Modals/InAppNotificationURI";
import dateTimeService from "../../../../util/date-time.service";
import { CONDITIONAL_NOTIFICATION_TYPE } from "../../../../constants/constantTypes";
import {
  CONDITIONAL_NOTIFICATION,
  YEAR_FORMAT,
} from "../../../../constants/constant";

import "./AddNotification.less";

export default function GeneralDetails(props) {
  const [showAddModal, setShowAddModal] = useState(false);
  const { form } = props;

  const disabledDate = (current) => {
    return (
      current && current.format(YEAR_FORMAT) < moment().format(YEAR_FORMAT)
    );
  };

  const disabledHours = () => {
    let hours = [];
    const current = form.getFieldsValue(true);
    let date = current?.date;
    const time =
      date?.format(YEAR_FORMAT) == moment().format(YEAR_FORMAT)
        ? moment().hour()
        : 0;
    for (let i = 0; i < time; i++) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    let minutes = [];
    const current = form.getFieldsValue(true);
    let date = current?.date;
    const time =
      date?.format(YEAR_FORMAT) == moment().format(YEAR_FORMAT) &&
      selectedHour === moment().hour()
        ? moment().minute()
        : 0;
    for (let i = 0; i < time; i++) {
      minutes.push(i);
    }
    return minutes;
  };

  const onChange = (e) => {
    form.setFieldsValue({
      linkSpanish: undefined,
      linkEnglish: undefined,
      type: undefined,
      title: undefined,
      activityDayId: undefined,
      freebieId: undefined,
      isInAppRedirection: e.target.checked,
    });
  };
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };
  const handleOpenAddModal = () => {
    setShowAddModal(true);
  };
  return (
    <>
      <InAppNotificationURI
        form={form}
        visible={showAddModal}
        onCancel={handleCloseAddModal}
      />
      <Card title="General Details" className="general">
        <Skeleton loading={props?.detailsLoader} active>
          <Row className="gx-m-0 gx-mb-4 gx-flex-row">
            <Col span={12} className="gx-pl-0">
              <Form.Item
                name="name"
                label="Name"
                required={false}
                rules={[{ required: true, message: "Name is required" }]}
              >
                <Input max={250} />
              </Form.Item>
            </Col>
            <Col span={12} className="gx-pr-0">
              <Form.Item
                name="nameSpanish"
                label="Spanish Name"
                required={false}
                rules={[
                  { required: true, message: "Spanish name is required" },
                ]}
              >
                <Input max={250} />
              </Form.Item>
            </Col>
            <Col span={24} className="gx-p-0">
              <Row className="gx-flex-row">
                <Form.Item dependencies={["isInAppRedirection"]} noStyle>
                  {({ getFieldValue }) => {
                    return (
                      <Col
                        span={24}
                        className="gx-pl-0"
                        style={{
                          position: "absolute",
                          top: "-10px",
                          left: "80px",
                        }}
                      >
                        <Form.Item required={false} name="isInAppRedirection">
                          <Checkbox
                            checked={form.getFieldValue("isInAppRedirection")}
                            onChange={onChange}
                          >
                            Add Link
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    );
                  }}
                </Form.Item>
                <Form.Item dependencies={["isInAppRedirection"]} noStyle>
                  {({ getFieldValue }) => {
                    const isInAppRedirection = getFieldValue([
                      "isInAppRedirection",
                    ]);
                    return (
                      <Col span={12} className="gx-pl-0">
                        <Form.Item
                          name={isInAppRedirection ? "title" : "linkEnglish"}
                          label={isInAppRedirection ? "Title" : "Link"}
                          required={false}
                          rules={[
                            ...(isInAppRedirection
                              ? [
                                  {
                                    required: true,
                                    message: "Enter Title",
                                  },
                                ]
                              : [
                                  {
                                    pattern: Patterns.urlPattern,
                                    message: "Invalid Website URL.",
                                  },
                                ]),
                          ]}
                        >
                          <Input
                            {...(isInAppRedirection
                              ? {
                                  maxLength: 0,
                                  onClick: handleOpenAddModal,
                                  disabled: form.getFieldValue("title"),
                                }
                              : { max: 250 })}
                          />
                        </Form.Item>
                      </Col>
                    );
                  }}
                </Form.Item>
                <Form.Item dependencies={["isInAppRedirection"]} noStyle>
                  {({ getFieldValue }) => {
                    const isInAppRedirection = getFieldValue([
                      "isInAppRedirection",
                    ]);
                    return (
                      <Col span={12} className="gx-pr-0">
                        <Form.Item
                          name={isInAppRedirection ? "type" : "linkSpanish"}
                          label={isInAppRedirection ? "Type" : "Spanish Link"}
                          required={false}
                          rules={[
                            ...(isInAppRedirection
                              ? [
                                  {
                                    required: true,
                                    message: "Enter Type",
                                  },
                                ]
                              : [
                                  {
                                    pattern: Patterns.urlPattern,
                                    message: "Invalid Website URL.",
                                  },
                                ]),
                          ]}
                        >
                          <Input
                            {...(isInAppRedirection
                              ? {
                                  maxLength: 0,
                                  onClick: handleOpenAddModal,
                                  disabled: form.getFieldValue("title"),
                                }
                              : { max: 250 })}
                          />
                        </Form.Item>
                      </Col>
                    );
                  }}
                </Form.Item>
              </Row>
            </Col>

            <Col span={8} className="gx-pl-0">
              <Form.Item name="isConditional" label="Conditional Notification">
                <Select placeholder="Select">
                  {CONDITIONAL_NOTIFICATION?.map((data) => {
                    return (
                      <Select.Option key={data?.value} value={data.value}>
                        {data?.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Time"
                name="time"
                required={false}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const date = form.getFieldValue("date");
                      const time = form.getFieldValue("time");
                      if ((date && !time) || (!date && !time)) {
                        return Promise.reject("Time is required.");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <TimePicker
                  format={dateTimeService.DATE_TIME_FORMAT.TIME}
                  disabledHours={disabledHours}
                  disabledMinutes={disabledMinutes}
                  style={{ width: "100%" }}
                  use12Hours
                />
              </Form.Item>
            </Col>

            <Col span={8} className="gx-pr-0">
              <Form.Item
                label="Date"
                name="date"
                required={false}
                rules={[
                  () => ({
                    validator(_, value) {
                      const date = form.getFieldValue("date");
                      const time = form.getFieldValue("time");
                      if ((time && !date) || (!date && !time)) {
                        return Promise.reject("Date is required.");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  format={dateTimeService.DATE_TIME_FORMAT.US_DATE_FORMAT_SLASH}
                  disabledDate={disabledDate}
                  className="width-100per"
                />
              </Form.Item>
            </Col>

            <Col span={12} className="gx-pl-0">
              <Form.Item
                label="Message"
                name="message"
                required={false}
                rules={[
                  { required: true, message: "English Message is required" },
                ]}
              >
                <TextArea rows={5} maxLength={178} />
              </Form.Item>
            </Col>
            <Col span={12} className="gx-pr-0">
              <Form.Item
                label="Spanish Message"
                name="messageSpanish"
                required={false}
                rules={[
                  { required: true, message: "Spanish Message is required" },
                ]}
              >
                <TextArea rows={5} maxLength={178} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" className="gx-flex-row">
            <Col>
              <Form.Item dependencies={["isConditional"]} noStyle>
                {({ getFieldValue }) => {
                  const isConditional = getFieldValue("isConditional");
                  const component =
                    isConditional !== CONDITIONAL_NOTIFICATION_TYPE.YES ? (
                      <Button
                        type="primary"
                        className="save-rule"
                        htmlType="submit"
                        loading={props?.loading}
                        disabled={props?.loading}
                      >
                        Save
                      </Button>
                    ) : null;
                  return component;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Skeleton>
      </Card>
    </>
  );
}
