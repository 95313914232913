import React from "react";
import { Row, Skeleton, Drawer, Col, Image, Button } from "antd";

import {
  CHILDREN_ACTIVITY,
  CHILDREN_ACTIVITY_TIME_FRAME,
  CHILDREN_ACTIVITY_TYPE,
  COMPONENT_TYPE,
} from "../../../../constants/constant";
import { useChildActivity } from "../queries";
import SectionsContainer from "./ActivitySection";
import { ReactComponent as Clock } from "../../../../assets/images/clock-icon.svg";
import { ReactComponent as Check } from "../../../../assets/images/check-icon.svg";
import { ReactComponent as UserIcon } from "../../../../assets/images/user-icon.svg";
import { ReactComponent as Link } from "../../../../assets/images/icon-20-link-2.svg";
import {
  activitySectionType,
  activityStepType,
  iconWrapperType,
  stepContainerType,
} from "../types";

import PlaceholderImage from "../../../../assets/images/recomment-view-placeholder.jpg";

import "../index.less";

export const TextIconWrapper = ({
  Icon,
  text,
  className = "",
  textColor = "",
  isClickable,
}: iconWrapperType) => {
  return (
    <Col xs={24} className="gx-p-0">
      <div className="text-icon-wrapper">
        <Icon width={19} height={19} />
        <p
          className={`${
            textColor ?? "text-color-body"
          } gx-m-0 gx-fs-md ${className} gx-ml-2 w-90`}
          onClick={() => {
            isClickable && window.open(text);
          }}
        >
          {text}
        </p>
      </div>
    </Col>
  );
};

const StepContainer = ({ title, description, media }: stepContainerType) => {
  return (
    <Col xs={24} className="step-container">
      <p className="text-brand-primary-900 gx-m-0 gx-pb-2 gx-font-weight-medium gx-fs-lg">
        {title}
      </p>
      <p className="description-text text-color-body gx-mb-3 gx-m-0">
        {description}
      </p>
      <div className="gx-mb-3">
        <Image className="step-image" src={media.viewableLink} />
      </div>
    </Col>
  );
};

function ViewChildActivity({
  visible,
  onClose = () => {},
  selectedActivity,
}: {
  [key: string]: any;
}) {
  const {
    data: activityDetails = {},
    isLoading,
    isFetching,
  } = useChildActivity(selectedActivity?.id);
  const sortList = (a: activityStepType, b: activityStepType) =>
    a.order - b.order;

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      key="right"
      className="drawer-width custom-drawer"
      title="View Activity"
    >
      <Row gutter={[50, 6]} className="gx-m-0 gx-mb-3">
        <Skeleton
          active
          loading={isFetching || isLoading}
          className="gx-m-0 gx-pl-3 gx-pr-3"
        >
          <Row
            align="middle"
            justify="space-between"
            className=" gx-m-0 gx-pt-3 gx-pb-3 gx-pl-3 gx-pr-3"
            style={{ padding: "10px 0px !important " }}
          >
            <Col
              sm={24}
              xs={24}
              md={18}
              xl={20}
              lg={19}
              className="gx-p-0 gx-pr-4"
            >
              <div>
                <img
                  src={activityDetails?.logo?.viewableLink ?? PlaceholderImage}
                  alt="main"
                  className={`view-logo-image ${
                    !activityDetails?.logo?.viewableLink && "view-logo-border"
                  }`}
                />
              </div>
            </Col>
          </Row>
          <Col xs={24}>
            <p className="title gx-m-0 gx-p-0 gx-mt-3 gx-font-weight-medium gx-fs-xl">
              {activityDetails?.title}
            </p>
          </Col>
          {activityDetails?.timeframe ? (
            <Col xs={24}>
              <TextIconWrapper
                Icon={Clock}
                text={
                  CHILDREN_ACTIVITY_TIME_FRAME[activityDetails?.timeframe - 1]
                    ?.label
                }
              />
            </Col>
          ) : null}
          <Col xs={24} className="gx-mb-3">
            {activityDetails?.placeHolder?.viewableLink ? (
              <img
                className="img-placeholder pdf-image"
                src={activityDetails?.media?.[0]?.viewableLink}
                alt="img"
              />
            ) : (
              <img
                className="img-placeholder"
                src={activityDetails?.media?.[0]?.viewableLink}
              />
            )}
          </Col>
          {activityDetails?.type === CHILDREN_ACTIVITY.PRINTABLE ? (
            <Col xs={24} className="gx-mb-3">
              <Button
                className="download-btn"
                size="middle"
                onClick={() =>
                  window.open(activityDetails?.resource?.viewableLink, "_black")
                }
              >
                Download
              </Button>
            </Col>
          ) : null}
          <Col xs={24} className="gx-mb-3">
            <p className="text-brand-primary-900 gx-m-0 gx-mb-3 gx-font-weight-medium gx-fs-lg">
              Description
            </p>
            <p className="description-text text-color-body gx-m-0">
              {activityDetails?.description}
            </p>
          </Col>

          <Col xs={24} className="gx-mb-3">
            <p className="text-brand-primary-900 gx-m-0 gx-mb-3 gx-font-weight-medium gx-fs-lg">
              Children Age
            </p>
            {activityDetails?.ageGroups?.map(
              (item: { description: string }) => (
                <TextIconWrapper
                  Icon={UserIcon}
                  text={item?.description}
                  className="gx-fs-lg"
                />
              )
            )}
          </Col>

          <Col xs={24} className="gx-mb-3">
            <p className="text-brand-primary-900 gx-m-0 gx-mb-3 gx-font-weight-medium gx-fs-lg">
              Activity Type
            </p>
            {Object.keys(activityDetails)?.length && (
              <TextIconWrapper
                Icon={CHILDREN_ACTIVITY_TYPE[activityDetails?.type - 1]?.icon}
                text={CHILDREN_ACTIVITY_TYPE[activityDetails?.type - 1]?.label}
                className="gx-fs-lg"
              />
            )}
          </Col>

          <Col xs={24} className="gx-mb-3">
            <p className="text-brand-primary-900 gx-m-0 gx-mb-3 gx-font-weight-medium gx-fs-lg">
              isBoosted
            </p>
            {!!activityDetails?.isBoosted ? "Yes" : "No"}
          </Col>

          <Col xs={24} className="gx-mb-3">
            <p className="text-brand-primary-900 gx-m-0 gx-mb-3 gx-font-weight-medium gx-fs-lg">
              Topic
            </p>
            <Row gutter={[4, 8]}>
              {activityDetails?.topics?.map((item: { name: string }) => (
                <Col>
                  <div className="text-color-body topic-tag">{item.name}</div>
                </Col>
              ))}
            </Row>
          </Col>

          {/* tags map */}
          {activityDetails?.childrenActivityTags?.length > 0 && (
            <Col xs={24} className="gx-mb-3">
              <p className="text-brand-primary-900 gx-m-0 gx-mb-3 gx-font-weight-medium gx-fs-lg">
                Tags
              </p>
              <Row gutter={[4, 8]}>
                {activityDetails.childrenActivityTags.map(
                  (item: { name: string }) => (
                    <Col key={item.name}>
                      <div className="text-color-body topic-tag">
                        {item.name}
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </Col>
          )}

          {activityDetails?.link ? (
            <Col xs={24} className="gx-mb-3">
              <p className="text-brand-primary-900 gx-m-0 gx-mb-3 gx-font-weight-medium gx-fs-lg">
                Website
              </p>
              <TextIconWrapper
                Icon={Link}
                text={activityDetails?.link}
                className="gx-fs-lg cursor"
                textColor="link-text"
                isClickable={true}
              />
            </Col>
          ) : null}

          {activityDetails?.components ? (
            <Col xs={24} className="gx-mb-3">
              <p className="text-brand-primary-900 gx-m-0 gx-mb-3 gx-font-weight-medium gx-fs-lg">
                {COMPONENT_TYPE[activityDetails?.type]}
              </p>
              <Row gutter={[4, 8]}>
                {activityDetails?.components?.map((item: string) => (
                  <TextIconWrapper Icon={Check} text={item} />
                ))}
              </Row>
            </Col>
          ) : null}
          {activityDetails?.steps
            ?.sort(sortList)
            ?.map((item: activityStepType) => (
              <StepContainer {...item} />
            ))}
          {activityDetails?.sections?.map((item: activitySectionType) => (
            <SectionsContainer item={item} sortList={sortList} />
          ))}
        </Skeleton>
      </Row>
    </Drawer>
  );
}

export default ViewChildActivity;
