import { Form } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

import {
  useAddNotification,
  useNotificationDetails,
  useNotificationUserCount,
  useUpdateNotification,
} from "../queries";
import Topbar from "../../../Topbar";
import EstimateTarget from "./EstimateTarget";
import GeneralDetails from "./GeneralDetails";
import ConditionalRules from "./ConditionalRules";
import { modifyRules, onDetailSuccess } from "../helper";
import { AUTHENTICATED_ROUTES } from "../../../../routes/constant";
import {
  CONDITIONAL_NOTIFICATION_TYPE,
  CONNECTED_TYPE,
} from "../../../../constants/constantTypes";
import {
  API_STORAGE_KEY,
  CONDITIONAL_NOTIFICATION,
} from "../../../../constants/constant";

import "./AddNotification.less";
import dateTimeService, {
  changeFormat,
  changeFormatV2,
  getDateTimeCST,
} from "../../../../util/date-time.service";

export default function AddNotificationContent(props) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { history: { location: { state: listInfo, pathname } = {} } = {} } =
    props;

  const {
    mutateAsync: getNotificationCount,
    isLoading: notificationCountLoader,
    data: notificationCountDetials,
  } = useNotificationUserCount();

  const { data: notificationDetails = {}, isLoading: detailLoader } =
    useNotificationDetails({
      onSuccess: (res) => console.log(),
      id: listInfo?.id,
    });

  useEffect(() => {
    if (Object.keys(notificationDetails)?.length) {
      onDetailSuccess(notificationDetails, form, getNotificationCount);
    }
  }, [notificationDetails]);

  const { mutateAsync: createNotification, isLoading: addNotificationLoader } =
    useAddNotification({
      onSuccess: onSuccess,
    });

  const { mutate: updateNotification, isLoading: updateNotificationLoading } =
    useUpdateNotification({
      onSuccess: onSuccess,
    });

  function onSuccess() {
    queryClient.removeQueries("notificationManagement");
    if (listInfo?.id) {
      queryClient.removeQueries(
        "notificationManagement",
        "details",
        listInfo?.id
      );
    }
    history.push(AUTHENTICATED_ROUTES.PUSH_MANAGEMENT);
  }
  function handleFinish(values) {
    const allValues = form.getFieldsValue(true);
    const {
      activityDayId,
      freebieId,
      postId,
      exploreId,
      accessId,
      activityId,
      redirectionType,
    } = allValues;
    const rules = form.getFieldValue("rules");

    let date;
    if (values?.date?._d) {
      const startDate = changeFormat(values.date?._d);
      const startTime = changeFormat(
        values.time?._d,
        dateTimeService.DATE_TIME_FORMAT.TIME
      );
      date = getDateTimeCST(startDate, startTime);
    } else {
      date = null;
    }
    if (
      rules?.length &&
      values.isConditional === CONDITIONAL_NOTIFICATION_TYPE.YES
    ) {
      values["rules"] = Object.keys(rules[0])?.length
        ? modifyRules(rules)
        : null;
    }
    if (values?.isConditional) {
      values["isConditional"] =
        values?.isConditional === CONDITIONAL_NOTIFICATION_TYPE.YES
          ? true
          : false;
    }

    values["linkEnglish"] = values?.linkEnglish ? values?.linkEnglish : null;
    values["linkSpanish"] = values?.linkSpanish ? values?.linkSpanish : null;

    if (listInfo?.id) {
      updateNotification({
        ...values,
        // ...(modifiedRules && {rules: modifiedRules}),
        id: listInfo?.id,
        date,
        ...(activityDayId && { activityDayId }),
        ...(freebieId && { freebieId }),
        ...(postId && { postId }),
        ...(exploreId && { exploreId }),
        ...(accessId && { accessId }),
        ...(redirectionType && { redirectionType }),
      });
      return;
    }
    createNotification({
      ...values,
      date,
      ...(activityDayId && { activityDayId }),
      ...(freebieId && { freebieId }),
      ...(postId && { postId }),
      ...(exploreId && { exploreId }),
      ...(accessId && { accessId }),
      ...(redirectionType && { redirectionType }),
    });
  }

  return (
    <>
      <Topbar title={`${listInfo?.id ? "Update" : "Add"} Notification`} />
      <div className="gx-main-content-wrapper">
        <Form
          name="notification"
          layout="vertical"
          initialValues={{ isConditional: 2 }}
          form={form}
          onFinish={handleFinish}
        >
          <Form.Item dependencies={["isConditional"]} noStyle>
            {({ getFieldValue }) => {
              const isConditional = getFieldValue("isConditional");
              const component =
                isConditional === CONDITIONAL_NOTIFICATION_TYPE.YES ? (
                  <EstimateTarget
                    form={form}
                    data={notificationCountDetials}
                    loading={notificationCountLoader}
                  />
                ) : null;
              return component;
            }}
          </Form.Item>

          <GeneralDetails
            form={form}
            loading={addNotificationLoader || updateNotificationLoading}
            detailsLoader={detailLoader}
          />
          <Form.Item dependencies={["isConditional"]} noStyle>
            {({ getFieldValue }) => {
              const isConditional = getFieldValue("isConditional");
              const component =
                isConditional === CONDITIONAL_NOTIFICATION_TYPE.YES ? (
                  <ConditionalRules
                    form={form}
                    loading={addNotificationLoader || updateNotificationLoading}
                    getNotificationCount={getNotificationCount}
                  />
                ) : null;
              return component;
            }}
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
